/* src/css/Programs.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #EEEEEE;
  color: #333;
}

.programs-container {
  padding: 40px;
  text-align: center;
}

.programs-container h1 {
  font-size: 2.5rem;
  color: #2F1C75;
  margin-bottom: 40px;
}

.programs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.program-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.program-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.program-card h2 {
  font-size: 1.5rem;
  color: #2F1C75;
}

.program-price {
  font-size: 1.2rem;
  color: #4288A4;
}

.program-duration, .program-plan {
  font-size: 1rem;
  color: #666;
}

.program-plan {
  margin: 10px 0;
}
