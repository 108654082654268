.cupRules-container {
    padding: 20px;
    background-color: #EEEEEE;
    color: #2F1C75;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
  
  .cupRules-container h1 {
    font-size: 2.5rem;
    color: #2F1C75;
    margin-bottom: 20px;
  }
  
  .cupRules-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cupRules-section {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    transition: transform 0.2s;
  }
  
  .cupRules-section:hover {
    transform: scale(1.05);
  }
  
  .cupRules-section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .cupRules-section h3 {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .cupRules-section p, .cupRules-section ul {
    font-size: 1rem;
    text-align: left;
    margin: 10px 0;
  }
  
  .cupRules-section ul {
    padding-left: 20px;
  }
  
  .cupRules-section ul li {
    margin-bottom: 5px;
  }
  
  @media screen and (max-width: 960px) {
    .cupRules-section {
      width: 100%;
    }
  }
  