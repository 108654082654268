.container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  background-color: #EEEEEE;
  width: 100%;
  justify-content: center;
}

.vertically-spaced {
  padding-top: 4px;
  padding-bottom: 4px;
  align-self: stretch;
  align-self: center; /* Center horizontally */
}

.mt20 {
  margin-top: 20px;
}

.input-container {
  width: 100%;
  max-width: 300px;
}

.button-container {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center; /* Center horizontally */
}

.swapScreens {
  justify-content: center;
  align-items: center;
  background-color: #2F1C75;
  color: #fff;
  padding: 10px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.swapScreens:link, .swapScreens:visited {
  color: #ffffff; /* Ensure text color for normal and visited links */
  text-decoration: none; /* Remove underline for normal and visited links */
}

.swapScreens:hover {
  background-color: #4288A4;
}


/* Style for the link */
.agreement-link {
  color: #4288A4;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.agreement-link:hover {
  color: #2F1C75;
  text-decoration: underline;
}

.error-message {
  color: #D8000C; /* Red color for error messages */
  font-size: 12px;
  margin-top: 5px;
}

/* Additional button styles for Register/Login button */
.secondary-button {
  background-color: #4288A4;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.secondary-button:hover {
  background-color: #2F1C75;
}

.secondary-button2 {
  background-color: #4288A4;
  color: #fff;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.secondary-button2:hover {
  background-color: #2F1C75;
}

.fee-notice {
  padding: 0px 35px;
  color: red;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.checkbox-style {
  width: 30px;  /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  cursor: pointer; /* Add a pointer cursor for better UX */
}

.team-card2 {
  background: #fff;
  margin: 3px auto;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.team-card2:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}