.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  background-color: #EEEEEE;
  justify-content: center;
  width: 100%;
}

.home-header {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}

.button-group {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.nav-section3 {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15x;
  width: 100%;
}

.home-button {
  justify-content: center;
  font-size: 15px;
  align-items: center;
  background-color: #2F1C75;
  color: #fff;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.home-button:hover {
  background-color: #4288A4;
}

.schedule-link {
  background-color: #2F1C75;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.schedule-link:hover {
  color: #4288A4;
}

.video-section {
  margin-bottom: 40px;
  text-align: center;
}

.home-video {
  width: 70%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cards-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px;
}

.card-link {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 600px;
}

.card h3 {
  color: #2F1C75;
  margin-bottom: 10px;
}

.info-section {
  margin-bottom: 40px;
  text-align: center;
}

.gallery-section {
  margin-bottom: 40px;
  text-align: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.gallery-image {
  width: 100%;
  max-width: 33%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.instagram-section {
  text-align: center;
  margin-bottom: 40px;
  border-radius: 10px;
}

.instagram-embed {
  border-radius: 10px; /* Adjust the value as needed */
  overflow: hidden; /* To ensure the iframe corners are rounded */
}

.instagram-embed .rounded-border {
  border-radius: 10px; /* Match the container's border radius */
}

.registration-message {
  color: red;
  text-decoration-line: underline;
  font-weight: bold;
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
}
