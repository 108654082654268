body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #EEEEEE;
  color: #333;
}

.team-waitlist-container {
  padding: 40px;
  text-align: center;
}

.navbar2 {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 50px;
  padding: 1000px;
  border-radius: 8px;
}

.navbar2 button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2F1C75;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar2 button:hover {
  background-color: #4288A4;
  transform: scale(1.05);
}

.navbar3 {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
}

.navbar3 button {
  margin: 2.5px;
  padding: 5px 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #2F1C75;
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar3 button:hover {
  background-color: #4288A4;
  transform: scale(1.05);
}

.schedule-list {
  margin: 20px 0;
}

.game-card {
  background: #fff;
  margin: 10px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  max-width: 800px;
}

.game-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.game-card p {
  font-weight: bold;
}

.team-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.home-team, .away-team {
  flex: 1;
  margin: 0 10px;
}

.home-team p, .away-team p {
  margin: 5px 0;
}

/* Additional styles for the standings table */
.standings-list {
  margin: 20px 0;
}

.standings-list table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.standings-list th, .standings-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.standings-list th {
  background-color: #2F1C75;
  color: white;
  font-weight: bold;
}

.standings-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

.standings-list tr:hover {
  background-color: #ddd;
}

.standings-list td {
  font-size: 0.9rem;
}
