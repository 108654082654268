.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: #fff;
    border-radius: 5px;
    padding: 1% 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content {
    text-align: center;
  }
  
  .modal button {
    margin-top: 10px;
    margin: 10px;
    padding: 5px 10px;
    background-color: #2F1C75;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal button:hover {
    background-color: #4288A4;
  }
  