.payment-methods-container {
    padding: 40px;
    text-align: center;
    background-color: #EEEEEE;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .payment-methods-container h2 {
    margin-bottom: 20px;
    color: #2F1C75;
  }
  
  .payment-method {
    margin: 20px 0;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .payment-method h3 {
    color: #2F1C75;
    margin: 10px 0;
  }
  
  .payment-method p,
  .payment-method a {
    font-size: 1.2rem;
    color: #333;
    margin: 5px 0;
  }
  
  .payment-method a.payment-link {
    color: #2F1C75 !important; /* Ensure dark blue color */
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .payment-method a.payment-link:hover {
    color: #4288A4 !important; /* Ensure light blue color */
  }
  
  .payment-logo {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
  }
  