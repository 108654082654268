@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #EEEEEE;
  min-height: 65vh;
  position: relative;
}

.contact-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  color: #2F1C75;
  margin-bottom: 20px;
}

form {
  background: #FFFFFF;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

form label {
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  font-size: 1rem;
  color: #2F1C75;
}

form input, form textarea {
  margin-top: 5px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

form input:focus, form textarea:focus {
  border-color: #2F1C75;
  outline: none;
}

form textarea {
  resize: vertical;
  min-height: 100px;
}

form input[type="submit"] {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #2F1C75;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form input[type="submit"]:hover {
  background-color: #4288A4;
}

.success-message {
  text-align: center;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-message h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  color: #2F1C75;
  margin-bottom: 10px;
}

.success-message p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  color: #2F1C75;
}

.error {
  color: red;
  font-size: 0.875rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #2F1C75;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .contact-container h1 {
    font-size: 2rem;
  }

  form {
    padding: 15px;
  }

  form input, form textarea {
    font-size: 0.875rem;
  }

  form input[type="submit"] {
    font-size: 0.875rem;
  }

  .success-message h2 {
    font-size: 1.5rem;
  }

  .success-message p {
    font-size: 1rem;
  }
}
