.footer {
  background-color: black;
  padding: 20px 0;
  color: #EEEEEE;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 100%;
  margin-top: auto;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.footer-link {
  color: #4288A4;
  margin: 0 15px;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #2F1C75;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-contact a {
  color: #4288A4;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #2F1C75;
}
