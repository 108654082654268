body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #EEEEEE;
  color: #333;
}

.teams-container {
  padding: 40px;
  text-align: center;
}

.navbar2 {
  margin-bottom: 20px;
}

.navbar2 button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2F1C75;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navbar2 button:hover {
  background-color: #4288A4;
}

.teams-list {
  margin: 20px 0;
}

.team-card {
  background: #fff;
  margin: 10px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.user-team-info {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #2F1C75;
}

.team-roster {
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.player-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.player-card {
  background: #f8f8f8;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 0 1 150px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.player-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.team-actions, .create-team-form, .join-team-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem; /* Adds space between buttons */
  margin: 1rem 0; /* Adds some margin around the container */
}

.action-button, .submit-button, .waitlist-button, .home-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2F1C75;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.action-button:hover, .submit-button:hover, .waitlist-button:hover, .home-button:hover {
  background-color: #4288A4;
}

.team-input {
  margin: 10px auto;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #2F1C75;
  border-radius: 5px;
  width: 80%;
  max-width: 300px;
  outline: none;
  transition: border-color 0.3s ease;
}

.team-input:focus {
  border-color: #4288A4;
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #D9534F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #C9302C;
}

/* Custom styles for the select dropdown */
.team-select {
  margin: 10px 0;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #2F1C75;
  border-radius: 5px;
  width: 80%;
  max-width: 300px;
  outline: none;
  transition: border-color 0.3s ease;
  appearance: none;
  background-color: #ffffff;
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="%232F1C75" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 9l6 6 6-6"></path></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
}

.team-select:focus {
  border-color: #4288A4;
}

.team-select option {
  background-color: #ffffff;
  color: #333;
}

.team-select:disabled {
  color: #aaa;
}
