@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #EEEEEE;
  color: #2F1C75;
  transition: all 0.3s ease;
}

.about-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2F1C75;
}

.card {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.card-image {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-bottom: 15px;
}

.card-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #2F1C75;
}

.card-content h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #4288A4;
}

.card-content p {
  font-size: 1rem;
  color: #2F1C75;
}

@media (max-width: 600px) {
  .about-container h1 {
    font-size: 2rem;
  }


  .card-content h2 {
    font-size: 1.2rem;
  }

  .card-content h3 {
    font-size: 1rem;
  }

  .card-content p {
    font-size: 0.875rem;
  }
}
