body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #EEEEEE;
    color: #333;
  }
  
  .team-waitlist-container {
    padding: 40px;
    text-align: center;
  }
  
  .navbar2 {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
  }
  
  .navbar2 button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #2F1C75;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .navbar2 button:hover {
    background-color: #4288A4;
    transform: scale(1.05);
  }
  
  .waitlist-list {
    margin: 20px 0;
  }
  
  .player-card {
    background: #fff;
    margin: 10px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .player-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  