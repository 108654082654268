.terms-container {
    /* padding: 5px; */
    background-color: #EEEEEE;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .terms-container h2 {
    color: #2F1C75;
    text-align: center;
  }
  
  .terms-container h3 {
    color: #2F1C75;
  }
  
  .terms-container p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  