@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.navbar {
  background: black;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1500px;
  width: 100%;
  padding: 0 40px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.8rem;
  color: #fff;
}

.navbar-logo-image {
  height: 50px;
  margin-right: 10px;
}

.navbar-title {
  font-family: 'Montserrat', sans-serif;
  color: #EEEEEE;
  font-size: 1.8rem;
  white-space: nowrap; /* Prevent text wrapping */
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.8rem;
  color: #fff;
  align-items: center;
  z-index: 1100;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-item {
  height: 50px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding: 0 1rem;
  position: relative;
}

.nav-links p {
  margin: 0; /* Remove default margin */
  position: relative;
}

.nav-links p:after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  background-color: #4288A4;
  bottom: -4px; /* Adjust if needed to perfectly position the underline */
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.2s ease-out;
}

.nav-links:hover p:after {
  width: 100%;
}


.fa-bars, .fa-times {
  font-size: 1.8rem;
}

@media screen and (max-width: 1250px) {
  .nav-menu {
    display: none;
  }

  .menu-icon {
    display: flex;
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .nav-menu.active {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    margin-right: 10%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    background: black;
    justify-content: center;
    /* align-items: center; */
    overflow-y: auto;
  }

  .nav-links {
    text-align: center;
    padding: 0.3rem;
    width: 100%;
    font-size: 1rem;
  }


  .fa-times {
    font-size: 1.8rem;
    color: #fff;
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .navbar-logo {
    position: absolute;
    top: 18px;
    left: 18px;
    z-index: 1100;
  }

  .navbar-title {
    display: block;
    position: absolute;
    /* top: 18px; */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1100;
    white-space: nowrap;
    font-size: 80%;
  }
}

@media screen and (min-width: 961px) {
  .navbar-title {
    display: block;
    margin-left: 10px;
  }
}
